import Grid2 from '@mui/material/Unstable_Grid2';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import EventPositionsControlCard from './components/EventPositionsControlCard';
import EventPositionsTable from './components/EventPositionsTable';

function EventsModule() {
  const [filterBundleId, setFilterBundleId] = useState<number | undefined>(undefined);
  const [showControlCard, setShowControlCard] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedEventPositionId = searchParams.get('id') ? parseInt(searchParams.get('id')!) : undefined;
  const setSelectedEventPositionId = (customerId: number | undefined) => {
    if (customerId) {
      searchParams.set('id', customerId.toString());
    } else {
      searchParams.delete('id');
    }
    setSearchParams(searchParams);
  };

  return (
    <Grid2 
      container 
      xs={12} 
      justifyContent="space-between" 
      sx={{ 
        height: 'calc(100vh - 96px)', // Adjust this value based on your header/navigation height
        overflow: 'hidden'
      }}
    >
      <Grid2 
        xs={showControlCard ? 9 : 12} 
        paddingRight={showControlCard ? 2 : 0}
        sx={{ 
          height: '100%',
          overflow: 'auto'
        }}
      >
        <EventPositionsTable
          selectedEntityId={selectedEventPositionId}
          setSelectedEntityId={setSelectedEventPositionId}
          filterBundleId={filterBundleId}
          isFullscreen={!showControlCard}
          toggleFullscreen={() => setShowControlCard(!showControlCard)}
        />
      </Grid2>
      {showControlCard && (
        <Grid2 
          xs={3} 
          sx={{ 
            height: '100%',
            overflow: 'auto'
          }}
        >
          <EventPositionsControlCard
            selectedEntityId={selectedEventPositionId}
            setSelectedEntityId={setSelectedEventPositionId}
            filterBundleId={filterBundleId}
            setFilterBundleId={setFilterBundleId}
          />
        </Grid2>
      )}
    </Grid2>
  );
}

export default EventsModule;
