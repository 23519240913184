import { Add, Delete, FilterAlt, FilterAltOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useConfirm } from 'material-ui-confirm';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAddEventMutation, useAddEventPositionMutation, useDeleteEventPositionMutation, useGetEventQuery } from '../api';
import EventPositionForm from './EventPositionForm';

export interface EventPositionsControlCardProps {
  selectedEntityId?: number;
  setSelectedEntityId: (eventPositionId: number | undefined) => void;
  filterBundleId?: number;
  setFilterBundleId: (bundleId: number | undefined) => void;
}

function EventPositionsControlCard({
  selectedEntityId,
  setSelectedEntityId,
  filterBundleId,
  setFilterBundleId,
}: EventPositionsControlCardProps) {
  // State
  const confirm = useConfirm();
  const navigate = useNavigate();

  // Data
  const [addEvent, { isLoading: isAdding }] = useAddEventMutation();
  const [addEventPosition, { isLoading: isAddingPosition }] = useAddEventPositionMutation();
  const [deleteEventPosition, { isLoading: isDeleting }] = useDeleteEventPositionMutation();
  const { data: remoteEvent, isFetching, isSuccess, isError } = useGetEventQuery(selectedEntityId!, { skip: !selectedEntityId });
  const event = selectedEntityId !== undefined ? remoteEvent : undefined;

  // UI state
  const disabled = !event || false;

  // Update state if selected entity successfully loaded but empty returned
  React.useEffect(() => {
    if (isError || (!isFetching && isSuccess && !remoteEvent)) {
      setSelectedEntityId(undefined);
    }
  }, [remoteEvent, isSuccess, isFetching, isError, setSelectedEntityId]);

  // Update filter on change of bundle
  React.useEffect(() => {
    if (event) {
      if (filterBundleId !== undefined && filterBundleId !== event.bundle.bundleId) {
        setFilterBundleId(event.bundle.bundleId);
      }
    }
  }, [event, filterBundleId, setFilterBundleId]);

  // Modification controls
  const onEventAdd = () => {
    addEvent({})
      .unwrap()
      .then((id) => {
        enqueueSnackbar(`Auftritt wurde erstellt.`, {
          variant: 'success',
        });
        setSelectedEntityId(id);
      })
      .catch((error) => {
        enqueueSnackbar('Beim Erstellen ist ein Fehler aufgetreten.', { variant: 'error' });
      });
  };

  const onEventPositionAdd = (eventPositionId: number) => {
    if (!event) return;

    addEventPosition({ bundleId: event?.bundle?.bundleId, eventPositionId })
      .unwrap()
      .then((id) => {
        enqueueSnackbar(`Position wurde erstellt.`, {
          variant: 'success',
        });
        setSelectedEntityId(id);
      })
      .catch((error) => {
        enqueueSnackbar('Beim Erstellen ist ein Fehler aufgetreten.', { variant: 'error' });
      });
  };

  const onEventPositionDelete = () => {
    if (selectedEntityId) {
      confirm({ title: `Position '#${event?.position.id}' von Auftritt '#${event?.bundle?.bundleId}' löschen?` }).then(() => {
        deleteEventPosition(selectedEntityId)
          .unwrap()
          .then(() => {
            enqueueSnackbar('Position erfolgreich gelöscht.', { variant: 'success' });
            navigate(-1);
          })
          .catch(() => {
            enqueueSnackbar('Beim Löschen ist ein Fehler aufgetreten.', { variant: 'error' });
          });
      });
    }
  };

  const onFilterToggle = () => {
    if (!event) return;

    if (filterBundleId === undefined) {
      setFilterBundleId(event.bundle.bundleId);
    } else {
      setFilterBundleId(undefined);
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          borderRadius: '4px',
          border: '1px solid #e0e0e0',
        }}
      >
        <Grid2 xs={12} container rowSpacing={2} padding={2}>
          <Grid2 xs={12} container justifyContent="space-between">
            <Grid2>
              <Typography variant="h6">
                {event ? `Auftritt ${event?.bundle.bundleId} (${event?.position.posNumber})` : 'Auftritt'}
              </Typography>
            </Grid2>
            <Grid2 container columnSpacing={1}>
              <Grid2>
                <Tooltip
                  title={filterBundleId === undefined ? 'Nur Positionen von diesem Auftritt anzeigen.' : 'Alle Positionen anzeigen.'}
                >
                  <IconButton onClick={onFilterToggle} disabled={disabled} size="small">
                    {filterBundleId === undefined ? <FilterAltOff /> : <FilterAlt />}
                  </IconButton>
                </Tooltip>
              </Grid2>
              <Grid2>
                <LoadingButton
                  variant="outlined"
                  onClick={() => onEventPositionAdd(event?.position.id!)}
                  color="secondary"
                  startIcon={<Add />}
                  loading={isAddingPosition}
                  disabled={disabled}
                >
                  POS.
                </LoadingButton>
              </Grid2>
              <Grid2>
                <LoadingButton variant="outlined" onClick={onEventAdd} color="secondary" startIcon={<Add />} loading={isAdding}>
                  Neu
                </LoadingButton>
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2 xs={12}>
            <EventPositionForm fromEntity={event} disabled={disabled} />
          </Grid2>
          <Grid2 xs={12}>
            <Divider />
          </Grid2>
          <Grid2 xs={12}>
            <LoadingButton
              variant="outlined"
              fullWidth
              onClick={onEventPositionDelete}
              color="primary"
              startIcon={<Delete />}
              loading={isDeleting}
              disabled={disabled}
            >
              Löschen
            </LoadingButton>
          </Grid2>
        </Grid2>
      </Box>
    </React.Fragment>
  );
}

export default EventPositionsControlCard;
