import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useSearchParams } from 'react-router-dom';
import ServicesControlCard from './components/ServicesControlCard';
import ServicesTable from './components/ServicesTable';

function ServicesModule() {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedServiceId = searchParams.get('id') ? parseInt(searchParams.get('id')!) : undefined;
  const setSelectedServiceId = (customerId: number | undefined) => {
    if (customerId) {
      searchParams.set('id', customerId.toString());
    } else {
      searchParams.delete('id');
    }
    setSearchParams(searchParams);
  };

  return (
    <Grid2
      container xs={12}
      justifyContent="space-between"
      sx={{
        height: 'calc(100vh - 96px)', // Adjust this value based on your header/navigation height
        overflow: 'hidden'
      }}>
      <Grid2 xs={9} paddingRight={2} sx={{
        height: '100%',
        overflow: 'auto'
      }}>
        <ServicesTable selectedEntityId={selectedServiceId} setSelectedEntityId={setSelectedServiceId} />
      </Grid2>
      <Grid2 xs={3} sx={{
        height: '100%',
        overflow: 'auto'
      }}>
        <ServicesControlCard selectedEntityId={selectedServiceId} setSelectedEntityId={setSelectedServiceId} />
      </Grid2>
    </Grid2>
  );
}

export default ServicesModule;
