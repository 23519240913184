import { Delete, Fullscreen, FullscreenExit, InfoOutlined, Refresh } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridFilterModel,
  GridRowParams,
  GridRowSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import clsx from 'clsx';
import { useConfirm } from 'material-ui-confirm';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../_app/store';
import { useDeleteEventPositionMutation, useGetEventsQuery, useUpdateEventMutation } from '../api';
import { EventPositionStatus } from '../types';
import { eventsColumnVisibilityChanged, eventsSorted } from '../uiSlice';
import { eventPositionStatusToBackgroundColour, parseDateString } from '../utils';
import EventPositionInfoBox from './EventPositionInfoBox';

type CustomToolbarProps = {
  isFullscreen: boolean;
  toggleFullscreen: () => void;
};

function CustomToolbar({ isFullscreen, toggleFullscreen }: CustomToolbarProps) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Button size="small" startIcon={isFullscreen ? <FullscreenExit /> : <Fullscreen />} onClick={toggleFullscreen}>
        {isFullscreen ? 'Vollbildmodus aus' : 'Vollbildmodus an'}
      </Button>
    </GridToolbarContainer>
  );
}

export type EventPositionsTableProps = {
  selectedEntityId?: number;
  setSelectedEntityId: (customerId: number | undefined) => void;
  filterBundleId?: number;
} & CustomToolbarProps;

const EventPositionsTable = ({
  selectedEntityId,
  setSelectedEntityId,
  filterBundleId,
  isFullscreen,
  toggleFullscreen,
}: EventPositionsTableProps) => {
  const { data: events, isFetching, isError, refetch } = useGetEventsQuery();
  const [deleteEventPosition] = useDeleteEventPositionMutation();
  const [updateEvent] = useUpdateEventMutation();
  const confirm = useConfirm();
  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>(undefined);
  const sortModel = useSelector((state: RootState) => state.eventsUI.eventsSortingModel);
  const visibilityModel = useSelector((state: RootState) => state.eventsUI.eventsVisibilityModel);
  const [infoBoxOpen, setInfoBoxOpen] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Update filter
  useEffect(() => {
    if (filterBundleId !== undefined) {
      setFilterModel({
        items: [{ id: 1, field: 'ID', operator: 'equals', value: filterBundleId.toString() }],
      });
    } else {
      setFilterModel({
        items: [],
      });
    }
  }, [filterBundleId]);

  useEffect(() => {
    if (filterModel?.items.length === 0) {
      setFilterModel({
        items: [{ id: 1, field: 'customerName', operator: 'equals', value: undefined }],
      });
    }
  }, [filterModel]);

  // Handle row selection
  const handleRowSelectionModelChanged = (selectionModel: GridRowSelectionModel) => {
    if (selectionModel.length === 1) {
      setSelectedEntityId(selectionModel[0] as number);
    } else {
      setSelectedEntityId(undefined);
    }
  };

  // Table edit actions
  const onDelete = (params: GridRowParams) => {
    if (params.id) {
      confirm({
        title: 'Position löschen',
        description: 'Möchten Sie diese Position wirklich löschen?',
        confirmationText: 'Löschen',
        cancellationText: 'Abbrechen',
      })
        .then(() => {
          deleteEventPosition(params.id as number)
            .unwrap()
            .then(() => {
              enqueueSnackbar('Position wurde gelöscht.', {
                variant: 'success',
              });
              navigate(-1);
            })
            .catch((error) => {
              enqueueSnackbar('Beim Löschen ist ein Fehler aufgetreten.', { variant: 'error' });
            });
        })
        .catch(() => {});
    }
  };
  const onUpdateStatus = (params: GridRowParams, status: EventPositionStatus) => {
    if (params.row.position.id) {
      updateEvent({ positionId: params.row.position.id as number, position: { status: status } })
        .unwrap()
        .then(() => {
          enqueueSnackbar('Status wurde aktualisiert.', {
            variant: 'success',
          });
        })
        .catch((error) => {
          enqueueSnackbar('Beim Aktualisieren ist ein Fehler aufgetreten.', { variant: 'error' });
        });
    }
  };

  // Table columns
  const columns: GridColDef[] = [
    { field: 'ID', headerName: 'ID', width: 64, valueGetter: (params) => params.row.bundle.bundleId },
    { field: 'Pos', headerName: 'Pos', width: 64, valueGetter: (params) => params.row.position.posNumber },
    { field: 'customerId', headerName: 'K.-Nr.', width: 64, valueGetter: (params) => params.row.bundle.customerId },
    { field: 'customerType', headerName: 'K.-Art', width: 68, valueGetter: (params) => params.row.bundle.customerType },
    { field: 'customerName', headerName: 'K.-Name', width: 150, valueGetter: (params) => params.row.bundle.customerName },
    { field: 'date', headerName: 'Datum', width: 100, valueGetter: (params) => parseDateString(params.row.position.date), valueFormatter: (params) => params.value?.toLocaleDateString() },
    { field: 'duration', headerName: 'Dauer', width: 68, valueGetter: (params) => params.row.position.duration },
    { field: 'start', headerName: 'Von', width: 68, valueGetter: (params) => params.row.position.start },
    { field: 'end', headerName: 'Bis', width: 68, valueGetter: (params) => params.row.position.end },
    { field: 'place', headerName: 'Ort', width: 128, valueGetter: (params) => params.row.position.place },
    {
      field: 'location',
      headerName: 'Adresse',
      width: 250,
      valueGetter: (params) =>
        params.row.position.address !== null && params.row.position.zip_code !== null && params.row.position.city !== null
          ? `${params.row.position.address}, ${params.row.position.zip_code} ${params.row.position.city}`
          : '',
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      valueGetter: (params) => params.row.position.name,
    },
    { field: 'type', headerName: 'Typ', width: 100, valueGetter: (params) => params.row.position.type },
    { field: 'description', headerName: 'Beschreibung', width: 200, valueGetter: (params) => params.row.position.description },
    {
      field: 'price_net',
      headerName: 'Preis (netto)',
      width: 150,
      valueGetter: (params) => params.row.position.price_net,
      renderCell: (params) => (params.value ? `${params.value} €` : ''),
    },
    {
      field: 'price_vat',
      headerName: 'MWSt',
      width: 150,
      valueGetter: (params) => params.row.position.price_vat,
      renderCell: (params) => (params.value ? `${params.value} €` : ''),
    },
    {
      field: 'price_gross',
      headerName: 'Preis (brutto)',
      width: 150,
      valueGetter: (params) => params.row.position.price_gross,
      renderCell: (params) => (params.value ? `${params.value} €` : ''),
    },
    {
      field: 'invoiceId',
      headerName: 'R.-Nr.',
      width: 64,
      valueGetter: (params) => params.row.position.invoiceId,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      valueGetter: (params) => params.row.position.status,
      cellClassName: (params) => clsx('MuiDataGrid-cell', eventPositionStatusToBackgroundColour(params.value as EventPositionStatus)),
    },
    {
      field: 'actionsEdit',
      headerName: 'Bearbeiten',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem icon={<InfoOutlined />} label="Info" onClick={() => setInfoBoxOpen(true)} />,
        <GridActionsCellItem icon={<Delete />} label="Löschen" onClick={() => onDelete(params)} />,
      ],
    },
    {
      field: 'actionsStatus',
      headerName: 'Status',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        ...Object.values(EventPositionStatus).map((status) => (
          <GridActionsCellItem label={status} onClick={() => onUpdateStatus(params, status)} showInMenu />
        )),
      ],
    },
  ];

  // Render
  return (
    <React.Fragment>
      <Grid2 xs={12} container rowSpacing={1}>
        <Grid2
          xs={12}
          sx={{
            '& .MuiDataGrid-cell.yellow': {
              backgroundColor: '#ffeb3b',
            },
            '& .MuiDataGrid-cell.orange': {
              backgroundColor: '#ff9800',
            },
            '& .MuiDataGrid-cell.red': {
              backgroundColor: '#f44336',
            },
            '& .MuiDataGrid-cell.black': {
              backgroundColor: '#000000',
              color: '#ffffff',
            },
            '& .MuiDataGrid-cell.darkblue': {
              backgroundColor: '#0000ff',
              color: '#ffffff',
            },
            '& .MuiDataGrid-cell.lightgreen': {
              backgroundColor: '#00ff00',
            },
            '& .MuiDataGrid-cell.darkgreen': {
              backgroundColor: '#008000',
              color: '#ffffff',
            },
            '& .MuiDataGrid-cell.blue': {
              backgroundColor: '#00ffff',
            },
            '& .MuiDataGrid-cell.brown': {
              backgroundColor: '#7B3F00',
            },
          }}
        >
          <DataGrid
            rows={events || []}
            columns={columns}
            getRowId={(row) => row.position.id}
            checkboxSelection={false}
            autoHeight
            rowSelectionModel={selectedEntityId !== undefined ? [selectedEntityId] : []}
            onRowSelectionModelChange={handleRowSelectionModelChanged}
            keepNonExistentRowsSelected
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => dispatch(eventsSorted(newSortModel))}
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            density="compact"
            slots={{
              toolbar: CustomToolbar,
            }}
            slotProps={{
              toolbar: {
                isFullscreen,
                toggleFullscreen,
              },
            }}
            columnVisibilityModel={visibilityModel}
            onColumnVisibilityModelChange={(newVisibilityModel) => dispatch(eventsColumnVisibilityChanged(newVisibilityModel))}
          />
        </Grid2>
        <Grid2 xs={12} container direction="row" columnSpacing={1} alignItems="center">
          <Grid2>
            <LoadingButton
              variant="text"
              size="small"
              color="info"
              startIcon={<Refresh />}
              onClick={() => setTimeout(refetch, 300)}
              loading={isFetching}
              loadingPosition="start"
            >
              Neu laden
            </LoadingButton>
          </Grid2>
          {isError && (
            <Grid2>
              <Typography variant="body2" color="error">
                Fehler: Varianten konnten nicht geladen werden.
              </Typography>
            </Grid2>
          )}
        </Grid2>
      </Grid2>
      <EventPositionInfoBox eventPositionId={selectedEntityId} open={infoBoxOpen} onClose={() => setInfoBoxOpen(false)} />
    </React.Fragment>
  );
};

export default EventPositionsTable;

/* 
{eventId && <EventInfoDialog open={isInfoDialogOpen} onClose={() => setIsInfoDialogOpen(false)} eventId={eventId!} />}
*/
